.ImagesSliderModal {
    width: 1000px !important;

    :global {
        @media (max-width: 767px) {
            width: 700px !important;
        }

        @media (max-width: 576px) {
            width: 500px !important;
        }

        .ant-modal-body {
            padding: 0;
        }

        .ant-modal-close {
            z-index: 20;
            border-radius: 100%;
            overflow: hidden;
            margin-top: 5px;
            margin-right: 5px;
            background-color: rgba(42, 40, 41, 0.53);
        }

        .ant-modal-close-x {
            font-size: 2.5em;
            color: lightgray;
        }

        .ant-modal-close-x:hover {
            color: white;
            background-color: rgba(42, 40, 41, 0.7);
        }
    }
}
