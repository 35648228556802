.propertyModalVideo {
    width: 100%;
    padding: 0;
    top: 0;
    background: transparent;
    height: 100vh;

    *:not(button):not(a) {
        background: transparent;
        height: 100%;
    }

    button {
        > span {
            font-size: 30px;
            color: white;
        }
    }

    > div > div {
        padding: 0;
        width: 95%;
        height: 95vh;
        transform: translate(2.5%, 2.5%);
    }
}
