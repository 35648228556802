.LoveHeart {
    :global {
        .heart-icon {
            stroke: rgb(255, 255, 255);
            stroke-width: 50px;
            color: transparent;
            transition: ease-in-out 0.3s all;
            vertical-align: -5px;
        }

        .heart-icon.dark {
            stroke: rgb(91, 91, 91);
        }

        .heart-icon.is-loved {
            color: rgba(59, 130, 246, 0.9);
        }

        .heart-icon:hover {
            color: rgba(59, 130, 246, 1);
        }

        .ant.dropdown .ant-dropdown-placement-bottomCenter {
            z-index: 10 !important;
        }
    }
}

.AddInspiration {
    :global {
        .heart-icon {
            stroke: rgb(68, 83, 90);
            stroke-width: 50px;
            color: transparent;
            transition: ease-in-out 0.3s all;
        }
        .heart-icon:hover {
            color: rgba(59, 130, 246, 1);
        }

        .ant-list-item-meta.title .ant-list-item-meta-title {
            width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .is-heart.is-loved {
            color: rgba(59, 130, 246, 0.9);
        }
    }

    .pulsate {
        -webkit-animation: pulsate 500ms ease-out;
        -webkit-animation-iteration-count: infinite;
        opacity: 0.5;
    }

    @-webkit-keyframes pulsate {
        0% {
            opacity: 0.5;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0.5;
        }
    }
}
