.PropertyCard {
    -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.2);

    .bg-blue-gradient {
        background: rgb(59, 130, 246);
    }

    .enquire-link {
        @media only screen and (max-width: 1439px) {
            margin-top: 12px;
        }

        @media only screen and (max-width: 1199px) {
            margin-top: 0;
        }

        a {
            color: #3b82f6;
            border: 1px solid #3b82f6;
            border-radius: 4px;
            width: 92px;
            height: 42px;
            line-height: 42px;
            display: inline-block;
            text-align: center;
            font-size: 16px;
            font-weight: 500;

            @media only screen and (max-width: 1439px) {
                width: 100%;
            }

            @media only screen and (max-width: 1199px) {
                width: 92px;
            }
        }
    }

    .isPropertyPage {
        display: flex;

        @media only screen and (max-width: 1600px) {
            display: block;
        }

        @media only screen and (max-width: 1439px) {
            display: block;
        }

        @media only screen and (max-width: 1199px) {
            display: flex;
        }
    }

    .textSizeSpecialCase {
        @media only screen and (min-width: 1200px) {
            font-size: 0.75rem;
        }
    }
}
