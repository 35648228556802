.feed-post-content.content-wrapper {
    position: relative;
    padding-bottom: 10px;

    > div:first-child {
        // max-height: 200px;
    }

    .content-toggle {
        position: absolute;
        z-index: 40;
        bottom: 10px;
        height: 100%; // margin-top: -5px;
        width: 100%;
        padding-bottom: 10px;
        background: rgba(255, 255, 255, 0);
        background: -moz-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.82) 86%,
            rgba(255, 255, 255, 0.91) 100%
        );
        background: -webkit-gradient(
            left top,
            left bottom,
            color-stop(0%, rgba(255, 255, 255, 0)),
            color-stop(86%, rgba(255, 255, 255, 0.82)),
            color-stop(100%, rgba(255, 255, 255, 0.91))
        );
        background: -webkit-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.82) 86%,
            rgba(255, 255, 255, 0.91) 100%
        );
        background: -o-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.82) 86%,
            rgba(255, 255, 255, 0.91) 100%
        );
        background: -ms-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.82) 86%,
            rgba(255, 255, 255, 0.91) 100%
        );
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.82) 86%,
            rgba(255, 255, 255, 0.91) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);

        span {
            position: relative;
            display: inline-block;
            top: 90%;
        }
    }

    &.content-readmore {
        padding-bottom: 60px;

        .content-toggle {
            height: auto;
        }
    }
}

.FeedSingleImage {
    img {
        @media screen and (max-width: 1199px) {
            height: 100%;
            max-width: none;
            width: auto;
        }

        @media screen and (max-width: 575px) {
            height: auto;
            max-width: 100%;
        }
    }

    @media screen and (max-width: 1199px) {
        height: 500px;
    }

    @media screen and (max-width: 575px) {
        height: auto;
    }
}

.FeedMultipleImages {
    .FeedImageBox {
        height: 250px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        @media screen and (max-width: 420px) {
            height: 200px;
        }
    }

    .FeedHalfImageBox {
        min-width: 46%;
    }

    .FeedFullImageBox {
        min-width: 98%;
    }
}
