.TopHeader {
    background-color: #1a202d;
    // line-height: 14rem;

    .publicMenu {
        border-bottom: none;
        background-color: #1a202d;
        li a {
            color: #fff;
        }
    }
    button {
        border: none;
        display: flex;
    }
}

.fixedTopHeader {
    height: 105px;
}

// .header {
//     // position: fixed;
//     // position: -webkit-sticky;
//     position: static;
//     top: 0;
//     //height: 56px;
//     //max-height: 56px;
//     z-index: 10;
//     width: 100%;

//     /* Small (sm) */
//     @media (min-width: 640px) {
//         position: static;
//     }
//     /* Medium (md) */
//     @media (min-width: 768px) {
//         position: static;
//     }

//     /* Medium (md) */
//     @media (min-width: 992px) {
//         position: static;
//         // position: -webkit-sticky;
//     }

//     ///* Large (lg) */
//     // @media (min-width: 1024px) {
//     //     position: fixed;
//     //     position: -webkit-sticky;
//     // }

//     button {
//         margin-right: 0.25rem;
//         font-weight: 600;
//         font-size: 1.25rem;
//     }
// }

.BottomHeader {
    line-height: 48px;
    background-color: #ffffff;
}

.animatedLine {
    cursor: pointer;
    white-space: nowrap;
    position: relative;

    &:before {
        background-color: #3b82f6;
        border-radius: 0px 0px 4px 4px;
        content: '';
        left: 0px;
        right: 0px;
        bottom: -4px;
        height: 2px;
        opacity: 0;
        position: absolute;
        transform-origin: left center;
        transform: scaleX(0);
        transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
        visibility: invisible;
        width: auto;
    }

    &:hover {
        &:before {
            transform: scaleX(1);
            visibility: visible;
            opacity: 1 !important;
        }
    }
}

.animatedLine2 {
    cursor: pointer;
    white-space: nowrap;
    position: relative;

    &:before {
        background-color: #3b82f6;
        border-radius: 0px 0px 4px 4px;
        content: '';
        left: 0px;
        right: 0px;
        bottom: -10px;
        height: 2px;
        opacity: 0;
        position: absolute;
        transform-origin: left center;
        transform: scaleX(0);
        transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
        visibility: invisible;
        width: auto;
    }

    &:hover {
        &:before {
            transform: scaleX(1);
            visibility: visible;
            opacity: 1 !important;
        }
    }
}

.animatedSelectedLine {
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    color: #3b82f6;

    &:before {
        background-color: #3b82f6;
        border-radius: 0px 0px 4px 4px;
        content: '';
        left: 0px;
        right: 0px;
        bottom: -10px;
        height: 2px;
        opacity: 1;
        position: absolute;
        visibility: visible;
        width: auto;
    }

    &:hover {
        &:before {
            transform: scaleX(1);
            visibility: visible;
            opacity: 1 !important;
        }
    }
}

.animatedSelectedLine2 {
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    color: #3b82f6;

    &:before {
        background-color: #3b82f6;
        border-radius: 0px 0px 4px 4px;
        content: '';
        left: 0px;
        right: 0px;
        bottom: -4px;
        height: 2px;
        opacity: 1;
        position: absolute;
        visibility: visible;
        width: auto;
    }

    &:hover {
        &:before {
            transform: scaleX(1);
            visibility: visible;
            opacity: 1 !important;
        }
    }
}

.publicMobileMenuDrawer {
    :global {
        .ant-drawer-content-wrapper {
            @apply max-w-xs;

            :global {
                .mobile-menu-dropdown {
                    a {
                        color: rgba(0, 0, 0, 0.85);
                    }
                }
            }

            :local {
                .RealtySubDomain {
                    border-right: none;
                }
            }
        }
    }
}
