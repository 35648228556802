.ImageCarousel {
    background: #eef0f3;

    .nav {
        width: 100px;
        height: 100%;
        z-index: 10;
        line-height: 30px;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        color: #666;
        position: absolute;
        transition: ease all 0.3s;
        display: none;

        &:first-of-type {
            margin-right: -100px;
        }

        &:last-of-type {
            margin-left: -100px;
        }

        .arrow {
            color: #fff;
            filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
            transition: ease all 0.5s;
            font-size: 2em;
            height: 100%;
        }
    }

    .nav.navPrev {
        background: linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 0.25) 100%);
    }

    .nav.navNext {
        background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.25) 100%);
        right: 0;
    }

    .nav.navPrev.disabled {
        display: none;
    }

    .nav.navNext.disabled {
        display: none;
    }

    &:hover {
        .nav {
            display: block;
        }
    }

    > div {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    :global {
        .BrainhubCarousel__dots {
            position: absolute;
            bottom: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            transform: translateX(-50%);

            .BrainhubCarousel__dot {
                opacity: 1;
                z-index: 999;
                position: relative;
                padding: 10px 5px;

                &:before {
                    background: #fff;
                    width: 7px;
                    height: 7px;
                }

                &:hover {
                    &:before {
                        background-color: #3b82f6;
                    }
                }
            }

            .BrainhubCarousel__dot.BrainhubCarousel__dot--selected {
                &:before {
                    background-color: #1d75bc;
                }
            }
        }
    }

    .imageCounterBox {
        background-color: rgba(0, 0, 0, 0.35);
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        padding: 3px 9px;
        margin-bottom: 15px;
        margin-left: 24px;
        height: auto;
        width: auto;

        @media only screen and (max-width: 760px) {
            margin-left: 12px;
        }
    }
}

.property-banner-mobile {
    padding-bottom: 75vh;

    @media only screen and (max-width: 420px) {
        padding-bottom: 28vh;
    }

    .BrainhubCarousel__dots {
        .BrainhubCarousel__dot {
            padding: 10px 4px;
        }
    }
}
