.publicLayout {
    :global {
        .default-theme {
            color: #495264;

            //input::placeholder {
            //    //color: rgb(71, 83, 92);
            //}

            .ant-input-prefix > i > svg {
                color: #8795a1;
            }

            //.full-page {
            //    min-height: calc(100vh - 64px);
            //    height: 100%;
            //}

            .header {
                z-index: 12;
                padding: 0;
                height: 100%;
            }
            body {
                cursor: default;
            }
        }

        .layout {
            background: white;

            .fixed-header {
                height: 105px;
            }

            .default-theme .header {
                padding: 0;
            }

            .fb_dialog.fb_dialog_advanced {
                right: 45pt !important;
            }

            .full-page {
                min-height: calc(100vh - 64px);
                height: 100%;
            }

            .show-item {
                opacity: 1;
                height: auto;
                transition: all 0.6s ease-in;
                transform: translateY(0);

                * {
                    opacity: 1;
                    transition: all 0.3s ease-in;
                }
            }

            .hide-item {
                height: 0;
                display: none !important;
                opacity: 0;
                transform: translateY(-100%);

                * {
                    opacity: 0;
                }
            }
        }
    }
}
