.hideListing {
    button {
        color: #718096;
        padding: 0;
    }

    button:hover {
        background-color: inherit;
    }

    :global {
        .button-hover:hover {
            transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity,
                box-shadow, transform, filter, backdrop-filter;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;
            transition-delay: 75ms;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            --tw-text-opacity: 1;
            color: #3b82f6;
        }
    }
}
