.MainFeatures {
    .propertyFeature {
        //padding: 3px 8px;
        //color: rgb(31, 104, 156);

        &:not(:last-child):not(:only-child) {
            border-right: 0;
            position: relative;

            //&:after {
            //    //content: '•';
            //    position: absolute;
            //    right: -10px;
            //    top: 50%;
            //    transform: translateY(-50%);
            //    color: rgb(163, 220, 233);
            //}
        }

        &Placeholder {
            width: 20px;
        }
    }
}
