.ImagesSlider {
    background: #495264;
    height: 100%;

    &:after {
        content: '';
        height: 25%;
        width: 100%;
        background: rgba(231, 56, 39, 0);
        background: -moz-linear-gradient(
            top,
            rgba(231, 56, 39, 0) 0%,
            rgba(255, 255, 255, 0) 27%,
            rgba(0, 0, 0, 0.5) 100%
        );
        background: -webkit-gradient(
            left top,
            left bottom,
            color-stop(0%, rgba(231, 56, 39, 0)),
            color-stop(27%, rgba(255, 255, 255, 0)),
            color-stop(100%, rgba(0, 0, 0, 0.5))
        );
        background: -webkit-linear-gradient(
            top,
            rgba(231, 56, 39, 0) 0%,
            rgba(255, 255, 255, 0) 27%,
            rgba(0, 0, 0, 0.5) 100%
        );
        background: -o-linear-gradient(
            top,
            rgba(231, 56, 39, 0) 0%,
            rgba(255, 255, 255, 0) 27%,
            rgba(0, 0, 0, 0.5) 100%
        );
        background: -ms-linear-gradient(
            top,
            rgba(231, 56, 39, 0) 0%,
            rgba(255, 255, 255, 0) 27%,
            rgba(0, 0, 0, 0.5) 100%
        );
        background: linear-gradient(
            to bottom,
            rgba(231, 56, 39, 0) 0%,
            rgba(255, 255, 255, 0) 27%,
            rgba(0, 0, 0, 0.5) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e73827', endColorstr='#000000', GradientType=0);
        z-index: 10;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .image-slider-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        /* only if you want to change the blur-up option from auto to always */
        /*font-family: "blur-up: always", "object-fit: cover";*/
        object-fit: contain;
    }

    position: relative;

    *:not(.slick-dots):not(.slick-prev):not(.slick-next) {
        height: 100%;
        //width: 100%;
        //object-fit: cover;
        //object-position: 100% 50%;
    }

    .react-slideshow-fade-wrapper {
        width: 100%;
        overflow: hidden;
    }

    .react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap {
        display: flex;
        flex-wrap: wrap;
    }

    .react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap * {
        max-width: 100%;
    }

    .react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap > div {
        position: relative;
        opacity: 0;
    }

    .react-slideshow-container {
        display: flex;
        align-items: center;
    }

    .react-slideshow-container .nav {
        height: 30px;
        background: transparent;
        width: 100px;
        z-index: 10;
        line-height: 30px;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        color: #fff;
    }

    //.react-slideshow-container .nav .arrow:hover {
    //  color: #FFF;
    //  opacity: 1;
    //  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
    //}

    .react-slideshow-container .nav .arrow {
        color: #fff;
        filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
        transition: ease all 0.5s;
        font-size: 2em;
    }

    .react-slideshow-container .nav-next {
        background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.25) 100%);
        color: #666;
    }

    .react-slideshow-container .nav-prev {
        background: linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 0.25) 100%);
        color: #666;
    }

    .react-slideshow-container .nav:first-of-type {
        margin-right: -100px;
    }

    .react-slideshow-container .nav:last-of-type {
        margin-left: -100px;
    }

    div > div.indicators {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    div > div.indicators > div {
        width: 7px;
        height: 7px;
        margin-right: 10px;
        border-radius: 50%;
        background: #ccc;
        cursor: pointer;
    }

    div > div.indicators > div:hover {
        background: #666;
    }

    div > div.indicators > div.active {
        background: #000;
    }

    div > div.indicators > div:last-of-type {
        margin-right: 0;
    }
}
