.carouselContainer > div {
    height: 100%;
}

.propertyCardActions {
    .action {
        background: rgba(0, 0, 0, 0.4);
        color: white;
        font-weight: 700;
        border-style: none;
        border-radius: 9999px;
        line-height: 0.4rem;
        width: 40px;

        &:hover {
            color: #3b82f6;
        }
    }
}

.photos {
    &:hover {
        .ofiEntry {
            opacity: 1;
        }
    }
}

.propertyCardCarousel {
    //position: relative;

    //*:not(.slick-dots):not(.slick-prev):not(.slick-next):not(.indicators):not(.indicators-container) {
    //    height: 100%; //object-fit: cover;
    //}
}
