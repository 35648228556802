.GuestLoginForm {
    padding: 36px 62px 40px;

    @media screen and (max-width: 639px) {
        padding: 36px 40px 40px;
    }

    @media screen and (max-width: 414px) {
        padding: 20px 25px 40px;
    }

    h1 {
        @media screen and (max-width: 639px) {
            margin-bottom: 20px;
        }
    }

    .primaryAccountTypeBox {
        @media screen and (max-width: 507px) {
            display: none;
        }
    }

    .secondaryAccountTypeBox {
        display: none;

        @media screen and (max-width: 507px) {
            display: flex;
        }
    }

    .accountTypeBox {
        @media screen and (max-width: 639px) {
            margin-right: 5px;
            margin-left: 5px;
            margin-bottom: 12px;
        }

        @media screen and (max-width: 507px) {
            margin-right: 10px;
            margin-left: 10px;
            margin-bottom: 12px;
        }
    }

    .accountTypeBoxActive {
        border: 1px solid #3b82f6;
        border-radius: 3px;

        .accountTypeImage {
            background-color: #3b82f6;
        }

        .accountTypeLabel {
            background-color: #3b82f6;
            color: #fff;
        }
    }

    .accountTypeImage {
        width: 84px;
        height: 84px;
        border-radius: 3px;
        overflow: hidden;
    }

    .accountTypeLabel {
        padding: 2px 0;
    }

    .accountTypeDivider {
        height: 2px;
        background-color: #c6c7ca;
        margin: 29px 0;

        @media screen and (max-width: 639px) {
            margin: 17px 0;
        }
    }

    .guestLoginForm {
        :global {
            .ant-input-affix-wrapper-lg {
                padding: 8px 15px;
            }

            .ant-input-prefix {
                margin-right: 10px;
                font-size: 18px;
            }

            .password-form-item {
                margin-bottom: 13px;
            }

            .additional-options {
                div:last-child {
                    color: #8e8e93;
                }
            }
        }

        .buttonFormItem {
            margin-bottom: 18px;

            :global {
                .ant-btn-lg {
                    height: 45px;
                }
            }
        }
    }

    .signUpOption {
        a {
            text-decoration: underline;
            color: #3b82f6;
            font-weight: 500;

            @media screen and (max-width: 320px) {
                display: block;
            }
        }
    }
}
