.PropertyPage {
    //* {
    //    transition: all ease-in 0.3s;
    //}

    .propertyMap {
        transition: none;
        // height: 456px;
        *:not(.ant-btn) {
            transition: none;
        }
    }

    .property-enquiry-panel {
        &.ant-collapse-item-active {
            > div:first-child {
                i.arrow {
                    display: none;
                }
            }
        }
    }

    .propertyBanner {
        height: 75vh;
    }

    .property-navbar-btn {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    :global {
        .breadcrumb-header {
            @media only screen and (max-width: 480px) {
                padding-top: 8px;
                padding-bottom: 8px;
                padding-left: 0.75rem;
                padding-right: 0.75rem;
                margin: 0px;
            }
        }
    }

    .back-search-btn {
        // color: #3b82f6;
        // font-size: 14px;
        // font-weight: 500;

        &:hover {
            color: #75dfff;
        }

        @media only screen and (max-width: 768px) {
            font-size: 12px;
        }
    }

    .property-summary-map {
        background-color: #fafafa;
        justify-content: flex-end;
    }

    // .private-inspection-btn {
    // background-color: #3b82f6;
    //     font-weight: 500;
    //     color: black;
    //     border: 1px solid gray;
    // border: 1px solid #3b82f6;
    //     border-radius: 0.5rem;
    //     padding: 5px 12px;
    //     font-size: 15px;
    //     margin-top: 4rem;
    // }

    .getStartedAlertsDesktop {
        // width: 345px;
        // margin: 0 1rem;

        @media only screen and (max-width: 1439px) {
            width: 100%;
        }

        .getStartedAlertsText {
            // width: 222px;
            height: auto;

            @media only screen and (max-width: 1439px) {
                width: 100%;
            }

            .getStartedAlertsLink {
                // color: #fff;
                // background-color: #3b82f6;
                width: 122px;
                height: 36px;
                line-height: 36px;
            }
        }

        .getStartedAlertsImg {
            // width: 119px;
            height: auto;

            // @media only screen and (max-width: 1439px) {
            //     display: none;
            // }
        }
    }

    .school-calculator-row {
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        padding: 50px 0;
    }

    .exploreSuburbBoxDesktop {
        h3 {
            font-size: 20px;
        }

        a {
            display: inline-block;
            font-size: 18px;
            // border: 2px solid #3b82f6;
            // border-radius: 4px;
            // color: #3b82f6;
            width: 224px;
            height: 48px;
            line-height: 44px;
        }
    }

    @media only screen and (max-width: 1199px) {
        .PropertyActionsBar {
            justify-content: center;
        }
    }

    @media only screen and (max-width: 420px) {
        .propertyBanner {
            height: 28vh;
        }
    }

    @media only screen and (max-width: 360px) {
        .propertyContent p.text-base {
            padding: 0 10px;
        }
    }

    .tabsContainer {
        :global {
            .ant-tabs-nav-wrap {
                display: table;
                // table-layout: fixed;
                table-layout: auto;
                width: 100%;
                .ant-tabs-tab {
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                }
            }
            .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
            .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
                color: white;
                background: #3b82f6;
            }
            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: white;
                text-shadow: 0 0 0.25px currentColor;
            }
            .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
            .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
                display: none;
            }
        }
    }
}
